import { nandNodeType, invNodeType, andNodeType, demuxNodeType, selector16NodeType } from 'diagram/missions/logicMissions';
import { SequentialTest, Step } from '../sequentialTest';
import { word, bit, pins } from '../pins';
import { MemoryState, MemoryStateView, DelegateStateView } from '../stateViews';
import { ComponentCounter, ComponentInternalState } from '../componentType';
import { dff16NodeType } from './registerMission';
import { diagram } from './missions';
import { BaseBuiltinComponentType } from './baseNodeType';
import { depends } from './dependency';
import { ComponentInstanceState } from 'diagram/componentState';


class Ram2State implements ComponentInternalState, MemoryState {
    newState: number[] = [];
    oldState: number[] = [];
    stateView = new MemoryStateView(this);

    constructor(private readonly node: ComponentInstanceState) { }
    resolveOutputs(node: ComponentInstanceState) {
        const st = node.inputConnectorStates.itemAt(0).bitState;
        const data = node.inputConnectorStates.itemAt(1).numState;
        const ad = node.inputConnectorStates.itemAt(2).numState;
        const clock = node.inputConnectorStates.itemAt(3).bitState;
        return this.resolve(st, data, ad, clock);
    }
    get address() { return this.node.inputConnectorStates.itemAt(2).numState; }


    peekState(addr: number) {
        return new DelegateStateView(() => this.peek(addr), 'WordCompact');
    }

    peek(addr: number) {
        return this.oldState[addr] ?? 0;
    }

    resolve(st: boolean, data: number, addr: number, clock: boolean) {
        if (!clock) {
            this.oldState[addr] = this.newState[addr] ?? 0;
        } else {
            if (st) {
                this.newState[addr] = data;
            }
        }
        // if the address have never ween written to, it is undefined. We default to 0
        const value = this.peek(addr);
        return [value] as const;
    }

    reset() {
        this.oldState = [];
        this.newState = [];
    }
}


export class RamState implements ComponentInternalState, MemoryState {
    nextState: number[] = [];
    oldState: number[] = [];
    stateView = new MemoryStateView(this);

    constructor(private node: ComponentInstanceState) { }
    resolveOutputs(node: ComponentInstanceState) {
        const ramState = node.internalState as RamState;
        const st = node.inputConnectorStates.itemAt(0).bitState;
        const data = node.inputConnectorStates.itemAt(1).numState;
        const addr = node.inputConnectorStates.itemAt(2).numState;
        const clock = node.inputConnectorStates.itemAt(3).bitState;
        return ramState.resolve(st, data, addr, clock);
    }
    get address() { return this.node.inputConnectorStates.itemAt(2).numState; }

    peekState(addr: number) {
        return new DelegateStateView(() => this.peek(addr), 'WordCompact');
    }

    peek = (addr: number) => this.oldState[addr] ?? 0;

    resolve(st: boolean, data: number, addr: number, clock: boolean) {
        if (!clock) {
            this.oldState[addr] = this.nextState[addr] ?? 0;
        } else {
            if (st) {
                this.nextState[addr] = data;
            } else {
                this.nextState[addr] = this.oldState[addr] ?? 0;
            }
        }
        // if the address have never ween written to, it is undefined. We default to 0
        const value = this.peek(addr);
        return [value] as const;
    }

    reset() {
        this.oldState = [];
        this.nextState = [];
    }
}

export const ramMission = diagram({
    key: 'RAM',
    inputPins: [bit('ad'), bit('st'), word('X'), bit('cl')],
    outputPins: [word()],
    palette: [nandNodeType, invNodeType, andNodeType, dff16NodeType, demuxNodeType, selector16NodeType],
    tests: [
        // ad, st, d, cl
        new SequentialTest([
            Step.set([1, 1, 42, 0],
                'Set <b>ad</b>=1, <b>st</b>=1 and <b>X</b>=42. Should store the number 42 at address 1'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete. The stored number should now be emitted.'),
            Step.assertOutput('', 42),
            Step.set([0, 0, 42, 0],
                'Set <b>ad</b>=1 and <b>st</b>=0 - change address to 0, without storing. The value 0 from register 0 should be emitted.'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick. The number stored at address 0 should now be emitted.'),
            Step.assertOutput('', 0),
        ]),
        new SequentialTest([
            // ad, st, d, cl
            Step.set([1, 0, 42, 0],
                'Set <b>ad</b>=1, <b>st</b>=0 and <b>X</b>=42. Should not store anything when st=0. '),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0.'),
            Step.assertOutput('', 0),
        ]),
        new SequentialTest([
            Step.set([0, 1, 42, 0],
                'Set <b>ad</b>=0, <b>st</b>=1 and <b>X</b>=42. Should store the number 42 at address 0'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete. The stored number should now be emitted.'),
            Step.assertOutput('', 42),
            Step.setPin('ad', 1, 'Set <b>ad</b>=1 - - change address to 1.'),
            Step.setPin('st', 0, 'Set <b>st</b>=0 - - disable storing.'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock tick. The value 0 from register 1 should be emitted.'),
            Step.assertOutput('', 0),
        ]),
        // Test save two different values (first addr 1, then addr 0)
        new SequentialTest([
            Step.set([1, 1, 42, 0], 'Set <b>ad</b>=1, <b>st</b>=1 and <b>X</b>=42. Should store the number 42 at address 1'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete. The stored number should now be emitted.'),
            Step.assertOutput('', 42),
            Step.set([0, 1, 8, 0],
                'Set <b>ad</b>=0 and <b>X</b>=8. Should store the number 8 at address 0'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete. The number 8 stored at address 0 should now be emitted.'),
            Step.assertOutput('', 8),
            Step.setAndTick([1, 0, 0, 0], 'Set <b>ad</b>=1, <b>st</b>=0. Change address to 1, but dont store anything.'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick. The number previously stored at address 1 should now be emitted'),
            Step.assertOutput('', 42),
        ]),
        // Test save two different values (first addr 0, then addr 1)
        new SequentialTest([
            Step.set([0, 1, 42, 0], 'Set <b>ad</b>=0 <b>st</b>=1 and <b>X</b>=42. Should store the number 42 at address 0'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete. The stored number should now be emitted.'),
            Step.assertOutput('', 42),
            Step.set([1, 1, 8, 0],
                'Set <b>ad</b>=1 and <b>X</b>=8. Should store the number 8 at address 1'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete. The number 8 stored at address 1 should now be emitted.'),
            Step.assertOutput('', 8),
            Step.setAndTick([0, 0, 0, 0], 'Set <b>ad</b>=0, <b>st</b>=0. Change address to 0, but dont store anything.'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick. The number previously stored at address 0 should now be emitted'),
            Step.assertOutput('', 42),
        ]),
    ],
    score: { min: 4 },
} as const);


export const ram2NodeType = new class extends BaseBuiltinComponentType {
    name = 'ram2';
    key = 'RAM2';
    inputs = pins(bit('st'), word('X'), word('ad'), bit('cl'));
    outputs = pins(word());
    hasInternalState = true;
    depends = depends(ramMission);
    createInternalState = (node: ComponentInstanceState) => new Ram2State(node);
};

// TODO
export const ram4Mission = diagram({
    key: 'RAM4',
    inputPins: [bit('a1'), bit('a0'), bit('st'), word('X'), bit('cl')],
    outputPins: [word()],
    palette: [
        ram2NodeType, nandNodeType, invNodeType, andNodeType, dff16NodeType, demuxNodeType,
        selector16NodeType
    ],
    tests: [
        // ad0, ad1, st, d, cl
        new SequentialTest([
            Step.set([0, 1, 1, 42, 0],
                'Set <b>a1</b>=0, <b>a0</b>=1,  <b>st</b>=1 and <b>X</b>=42. Should store the number 42 at address 1'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete. The stored number should now be emitted.'),
            Step.assertOutputs([42])
        ]),
        new SequentialTest([
            Step.set([0, 1, 1, 42, 0],
                'Set <b>a1</b>=0, <b>a0</b>=1, <b>st</b>=1 and <b>X</b>=42. Should store the number 42 at address 1'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick. The stored number should now be emitted.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete. The stored number should now be emitted.'),
            Step.assertOutputs([42]),
        ]),
        new SequentialTest([
            Step.set([0, 1, 1, 42, 0],
                'Set <b>a1</b>=0, <b>a0</b>=1, <b>st</b>=1 and <b>X</b>=42. Should store the number 42 at address 1'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete.'),
            Step.assertOutputs([42]),
            Step.set([0, 0, 1, 1234, 0],
                'Set <b>a0</b>=0, <b>st</b>=1 and <b>X</b>=1234. Should store the number 1234 at address 0'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete.'),
            Step.assertOutputs([1234]),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 - clock tick.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 - clock cycle complete.'),
            Step.setPin('a0', 1, 'Set <b>a0</b>=1. The number at stored at address 1 should still be 42.'),
            Step.assertOutputs([42])
        ]),
    ],
    score: { min: 2 }
} as const);


export const ram64kbNodeType = new class extends BaseBuiltinComponentType {
    name = 'ram';
    key = 'RAM';
    inputs = pins(bit('st'), word('X'), word('Ad'), bit('cl'));
    outputs = pins(word());
    hasInternalState = true;
    depends = depends(ramMission, 1, false);
    createInternalState = (node: ComponentInstanceState) => new RamState(node);
    /* override 'depends' for special-case text */
    componentCount = (counter: ComponentCounter) => {
        const ramCount = counter.countForMission(ramMission);
        if (ramCount.isError) {
            return ramCount;
        }
        // ram mission is 2 words = 4 bytes
        const ram64 = ramCount.mul(256);
        return counter.empty(`And ${ram64.count} for each kilobyte of RAM.`);
    };
};
