import { Token } from './tokenize';
import { Grammar, GrammarSet, parse as earley_parse, Subtree } from './earley';
import { Option, ErrorResult, SourcedErrorResult, EndOfSource } from './shared';


export class SyntaxNode {
    constructor(public type: string, public children: SyntaxNode[], public token?: Token) {}
}

/*  Kind of hacky how the token are matched with the terminals
 */
function toTree(node: Subtree, tokens: Token[]): SyntaxNode {
    const name = node.root;
    let terminal = undefined;
    if (!node.subtrees.length && name === tokens.first().type) {
        terminal = tokens.shift();
    }
    const children = node.subtrees.map(t => toTree(t, tokens));
    return new SyntaxNode(name, children, terminal);
}

export function parse(startSymbol: string, tokens: Token[], grammar: GrammarSet): Option<SyntaxNode> {
    try {
        tokens = tokens.slice();
        tokens.push({type: '$EOF'} as Token);
        grammar['$START'] = [[startSymbol, '$EOF']];

        const result = parseEarley('$START', tokens, grammar);
        if (result instanceof ErrorResult) {
            return result;
        }
        const tree = toTree(result, tokens.slice());
        return tree.children.first();
    } catch (e) {
        if (e instanceof ErrorResult) {
            return e;
        }
        console.error(e);
        return new ErrorResult(`Internal parser error: ${(e as Error).message} `);
    }
}

function parseEarley(rootRule: string, tokenNodes: Token[], grammarRules: GrammarSet): Option<Subtree> {
    const tokens = tokenNodes.map(t => t.type);

    const grammar = new Grammar(grammarRules);

    // Parsing
    const chart = earley_parse(tokens, grammar, rootRule);

    const root = chart.getFinishedRoot('$START');
    if (root === null) {
        console.log('PARSE ERROR - root not finished');
        console.log(chart);
        // find first columns with 0
        const firstEmptyIx = chart.chart.findIndex(c => c.length === 0);
        if (firstEmptyIx === -1) {
            /*
            const unfinished = chart.chart.at(-1).map(st => st.rhs[st.dot]);
            console.log('unfinished', unfinished);
            */
            // unexpected end of file.
            return new SourcedErrorResult(`Parse error: Unexpected end of code.`, new EndOfSource());
        }
        const errIx = firstEmptyIx - 1;
        const errorToken = tokenNodes.itemAt(errIx);
        /*
        console.log('errIx', errIx);
        console.log('error token', errorToken);
        const unfinished = chart.chart[errIx].map(st => st.rhs[st.dot]);
        console.log('unfinished', unfinished);
        */
        return new SourcedErrorResult(`Parse error: Unexpected '${errorToken.type}'`, errorToken);
    }
    // Get array with all parsed trees
    // In case of ambiguous grammar there might be more than 1 parsing tree
    // we just return the first
    const trees = root.traverse();
    const tree = trees.first();
    return tree;
}
