import { diagram } from './missions';
import { nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, selectorNodeType } from './logicMissions';
import { SequentialTest, Step } from '../sequentialTest';
import { PinGroup, bit, pins } from '../pins';
import { BaseBuiltinComponentType } from './baseNodeType';
import { depends } from './dependency';
import { latchNodeType } from './latchMission';
import { ComponentInternalState } from 'diagram/componentType';
import { DelegateStateView } from 'diagram/stateViews';
import { ComponentInstanceState } from 'diagram/componentState';

export class DffState implements ComponentInternalState {
    oldState = false;
    newState = false;
    stateView = new DelegateStateView(() => this.oldState ? 1 : 0, 'Bit');
    resolveOutputs(node: ComponentInstanceState) {
        const st = node.inputConnectorStates.itemAt(0).bitState;
        const data = node.inputConnectorStates.itemAt(1).bitState;
        const clock = node.inputConnectorStates.itemAt(2).bitState;
        const out = this.resolve(st, data, clock);
        return [out ? 1 : 0];
    }
    resolve(st: boolean, data: boolean, clock: boolean) {
        if (clock) {
            if (st) {
                this.newState = data;
            }
        } else {
            this.oldState = this.newState;
        }
        const out = this.oldState;
        return out;
    }
    reset() {
        this.oldState = false;
        this.newState = false;
    }
}


export const flipFlopMission = diagram({
    key: 'DFF',
    inputPins: [bit('st'), bit('d'), bit('cl')],
    outputPins: [bit('')],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, latchNodeType, selectorNodeType],
    tests: [
        // inputs: store, data, clock
        // NOTE: initial output is undefined, so don't test output until after first tick
        new SequentialTest([
            Step.setAllowUnstable('d', 1, 'Set <b>d</b>=1.'),
            Step.setAllowUnstable('st', 1, 'Set <b>st</b>=1. Should store 1.'),
            // dont check output yet, since it is undefined
            Step.setAllowUnstable('cl', 1, 'Set <b>cl</b>=1. Output not changed yet'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete). The stored 1 should now be output'),
            Step.assertOutput('', 1),
            // change back to 0
            Step.setPin('d', 0, 'Set <b>d</b>=0. Should store 0.'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1.'),
            Step.assertOutput('', 1, 'Output should not be changed yet.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete). The stored 0 should now be output'),
            Step.assertOutput('', 0),
        ]),
        new SequentialTest([
            Step.setAllowUnstable('st', 1, 'Set <b>st</b>=1 and <b>d</b>=0. Should store 0. Output should not reflect it yet'),
            Step.setAllowUnstable('cl', 1, 'Set <b>cl</b>=1.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete). The stored 0 should now be output'),
            Step.assertOutput('', 0),
            Step.setPin('d', 1, 'Set <b>d</b>=1 Should have no effect when <b>cl</b>=1'),
            Step.assertOutput('', 0, 'The stored 0 should still be output'),
        ]),
        // no change on tock
        new SequentialTest([
            Step.setAllowUnstable('st', 1, 'Set <b>st</b>=1.'),
            Step.setAllowUnstable('d', 0, 'Set <b>d</b>=0. Should store 0.'),
            Step.setAllowUnstable('cl', 1, 'Set <b>cl</b>=1.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete). The stored 0 should now be output'),
            Step.assertOutput('', 0),
        ]),
        // State should only change on clock tick
        /*
        new SequentialTest([
            Step.set([0, 1, 0], 'Set <b>d</b>=1. Should not store anything since <b>st</b> is 0.'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 (Clock tick). '),
            Step.assertOutput('', 0, 'Output should still be 0.'),
            Step.setPin('st', 1, 'Set <b>st</b>=1. Should not change state yet'),
            Step.assertOutput('', 0, 'Output should still be 0.'),
        ]),
        */
        // State should only change on clock tick
        new SequentialTest([
            Step.setAllowUnstable('d', 1, 'Set <b>d</b>=1.'),
            Step.setAllowUnstable('st', 1, 'Set <b>st</b>=1. Should store 1.'),
            Step.setAllowUnstable('cl', 1, 'Set <b>cl</b>=1 (Clock tick). '),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete). Output should be 1.'),
            Step.assertOutput('', 1, 'Output should be 1.'),
            Step.setPin('d', 0, 'Set <b>d</b>=0.'),
            Step.setPin('st', 0, 'Set <b>st</b>=0.'),
            Step.assertOutput('', 1, 'Output should still be 1.'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1. Since <b>st</b> is 0, the stored value should not change but remain 1.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete). '),
            Step.assertOutput('', 1, 'Output should still be 1.'),
        ]),
        new SequentialTest([
            Step.setAllowUnstable('d', 1, 'Set <b>d</b>=1.'),
            Step.setAllowUnstable('st', 1, 'Set <b>st</b>=1. Should store 1.'),
            Step.setAllowUnstable('cl', 1, 'Set <b>cl</b>=1 (Clock tick).'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete). The stored 1 should now be output'),
            Step.assertOutput('', 1),
            Step.setPin('d', 0, 'Set <b>d</b>=0.'),
            Step.setPin('st', 0, 'Set <b>st</b>=0.'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 (Clock tick).'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete).'),
            Step.assertOutput('', 1, 'The stored 1 should still be output. Since st=0 at time of clock tick, the output should not change.'),
        ]),
        /*
        new SequentialTest([
            Step.set([1, 1, 0], 'Set <b>d</b>=1 and <b>st</b>=1'),
            Step.setPin('st', 0, 'Set <b>st</b> back to 0 before clock tick.'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 (Clock tick). '),
            Step.assertOutput('', 0, 'Output should be 0. Since st was 0 at the time of the clock tick, d should not be stored.'),
        ]),
        */
       /*
        new SequentialTest([
            Step.set([0, 0, 0], 'Set <b>d</b>=0 and <b>st</b>=0'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 (Clock tick). '),
            Step.assertOutput('', 0, 'Output should be 0, since <b>st</b> was never set to <b>1</b>.'),
        ]),
        */
        /*
        new SequentialTest([
            // Reset output to 0 (since it is initially undefined)
            Step.setAllowUnstable('st', 1, 'Set <b>st</b>=1. Should store 0.'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 (Clock tick). Should now emit 0.'),
            Step.assertOutput('', 0, 'The stored 0 should now be output.'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0'),
            // Now the actual test. Check that st=1 only have effect at clock tick
            Step.setPin('d', 1, 'Set <b>d</b>=1.'),
            Step.assertOutput('', 0, 'The stored 0 should still be output.'),
            Step.setPin('st', 0, 'Set <b>st</b>=0.'),
            Step.setPin('cl', 1, 'Set <b>cl</b>=1 (Clock tick). Output should not change since st=0 at time of clock tick.'),
            Step.assertOutput('', 0, 'The stored 0 should still be output.'),
            Step.setPin('st', 1, 'Set <b>st</b>=1. Should not affect output yet'),
            Step.assertOutput('', 0, 'The stored 0 should still be output.'),
        ]),
        */
    ],
    score: { min: 4, nands: 13 },
} as const);

export const dffNodeType = new class extends BaseBuiltinComponentType {
    readonly name = 'dff';
    readonly key = 'DFF';
    readonly inputs = pins(bit('st'), bit('d'), bit('cl'));
    readonly outputs = pins(bit());
    readonly hasInternalState = true;
    readonly depends = depends(flipFlopMission);
    readonly createInternalState = () => new DffState();
};

export const dff2Mission = diagram({
    key: 'DFF2',
    inputPins: [bit('st'), new PinGroup('', [bit('d1'), bit('d0')]), bit('cl')],
    outputPins: [new PinGroup('', [bit('d1'), bit('d0')])],
    palette: [nandNodeType, invNodeType, andNodeType, orNodeType, xorNodeType, dffNodeType],
    tests: [
        // inputs: store, data1, data0, clock
        new SequentialTest([
            Step.setAllowUnstable('d1', 0, 'Set <b>d1</b>=0.'),
            Step.setAllowUnstable('d0', 1, 'Set <b>d0</b>=1.'),
            Step.setAllowUnstable('st', 1, 'Set <b>st</b>=1. Should store binary 01.'),
            Step.setAllowUnstable('cl', 1, 'Set <b>cl</b>=1'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete). The stored 01 should now be output'),
            Step.assertOutputs([0, 1]),
            Step.setPin('cl', 0, 'Set <b>cl</b>=1. Output should not change'),
            Step.assertOutputs([0, 1])
        ]),
        new SequentialTest([
            Step.setAllowUnstable('d1', 1, 'Set <b>d1</b>=1.'),
            Step.setAllowUnstable('d0', 0, 'Set <b>d0</b>=0.'),
            Step.setAllowUnstable('st', 1, 'Set <b>st</b>=1. Should store binary 10.'),
            Step.setAllowUnstable('cl', 1, 'Set <b>cl</b>=1'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete). The stored 10 should now be output'),
            Step.assertOutputs([1, 0])
        ]),
        // when st = 0, data should not be stored
        new SequentialTest([
            // Start by resetting
            Step.setAllowUnstable('d1', 0, 'Set <b>d1</b>=0.'),
            Step.setAllowUnstable('d0', 0, 'Set <b>d0</b>=0.'),
            Step.setAllowUnstable('st', 1, 'Set <b>st</b>=1. Should store binary 00.'),
            Step.setAllowUnstable('cl', 1, 'Set <b>cl</b>=1'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete). The stored 00 should now be output'),
            Step.assertOutputs([0, 0]),
            // changing data without storing
            Step.setPin('d1', 1, 'Set <b>d1</b>=1'),
            Step.setPin('st', 0, 'Set <b>st</b>=1'),
            Step.setAllowUnstable('cl', 1, 'Set <b>cl</b>=1'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete). The stored 00 should still be output'),
            Step.assertOutputs([0, 0])
        ]),
        // set output to 11, then change input when st=0 - this should not affect output
        new SequentialTest([
            Step.setAllowUnstable('d1', 1, 'Set <b>d1</b>=1.'),
            Step.setAllowUnstable('d0', 1, 'Set <b>d0</b>=1.'),
            Step.setAllowUnstable('st', 1, 'Set <b>st</b>=1. Should store binary 11.'),
            Step.setAllowUnstable('cl', 1, 'Set <b>cl</b>=1'),
            Step.setPin('cl', 0, 'Set <b>cl</b>=0 (Clock cycle complete). The stored 11 should now be output'),
            Step.assertOutputs([1, 1]),
            Step.setPin('st', 0, 'Set <b>st</b>=0, <b>d1</b>=0 and <b>d0</b>=0. Should not change data when <b>st</b> is 0.'),
            Step.assertOutputs([1, 1]),
            Step.setPin('cl', 0, 'Set <b>cl</b>=1 (Clock tick). Should not change data.'),
            Step.assertOutputs([1, 1]),
        ]),
    ],
    score: { min: 2 },
} as const);
